<template>
    <div class="onboard-img-container">
        <div class="img-wrapper">
            <img :src="require('@/assets/images/onboard/onboard-3.png')" alt="" />
        </div>
        <div class="content">
            <div class="title">매니저의 1:1 전담 케어</div>
            <div class="desc">
                <p>선호 사항 파악부터, 주선, 약속, 만남까지</p>
                <p>맞춤형으로 관리해드리는 프라이빗 서비스 입니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnBoard3',
}
</script>

<style scoped></style>
